footer{
    left:0;
    bottom:0;
    right:0;
    text-align: center;
    padding-bottom: 20px;
    color:  $dark-gray;
    font-family: $graphik-regular;
    font-weight: 800;
    position: fixed;
    bottom: 0;
    width: 100%;
}