$white: #FCFCF7;
$black: #0F151F;
$green: #5A9467;
$light-green: #DCE8DB;
$dark-green: #5a94677a;
$dark-gray: #4E4E4E;
$light-gray: #B1C0C9;
$label-color: rgb(231, 231, 231);
$details-color: rgb(170, 230, 253);
$connected-color:  #97D3A4;
$background-notification-error: #F9F4E4;
$background-notification-success: #DCE8DB;
$notifications-font-color-error: #DAAA30;
$notifications-font-color-success: #5A9467;

