@mixin container($background: $light-green, $width: 35%) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $background;
    padding: 25px;
    border-radius: 15px;
    position: absolute;
    width: $width;
    height: 369px;
    margin-top: 3%;
}
@mixin connectButton(){
    width: 100%;
    font-size: 12px;
}

.claimContainer{
    @include container();
}
.connectWindow{
    @include container($background: $white, $width: 28%);
    .welcome{
        display: flex;
        align-items: center;
        color: $black;
        padding: 10px;
        font-family: $graphik-bold;
        font-style: normal;
        font-size: 40px;
        line-height: 44px;
        div{
            margin-left: 5%;
        }
    }
    .guideline{
        color: $black;
        padding: 10px;
        font-family: $space-mono-regular;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
    }
}
.dataForm{
   width: 75%;
   flex: 2;
}
.balance{
    &:nth-child(1) {
        margin-top: 10%;
        margin-bottom: 5%;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20.5px 20px;
    background: $black;
    color: $white;
    border-radius: 5px;
    font-family: $space-mono-bold;
    .details{
        color: $green;
        font-family: $graphik-bold;
    }
    .freeTokensPopupWrapper{
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.5px;
        span{
            margin-right: 5px;
        }
    }
}

@include high-end {
    .claimContainer{
        width: 25%;
        height: 469px;
    }
}
@include desktop {
    .claimContainer {
        width: 35%;
    }
    .connectWindow {
        width: 33%;
    }
}
@include tablet {
    .claimContainer {
        width: 50%;
        margin-top:10%;
    }
    .connectWindow {
        width: 50%;
    }
}

@include mobile {
    .claimContainer {
        width: 90%;
        font-size: 12px;
        margin-top: 15%;
        .dataForm{
            width: 105%;
            flex: 0.5;
            .balance{
                padding: 14px;
                .freeTokensPopupWrapper{
                    margin: 0;
                }
            }
        }
        .buttonWrapper{
            flex: 0.25;
            width: 105%;
        }
    }
    .connectWindow {
        width: 85%;
        .guideline{
            font-size: 12px;
        }
    }
    .buttonConnecting{
        width: 100%;
        &.notConnected{
           @include connectButton();
        }
    }
}
@include mobile-semi {
    .claimContainer {
        background-color:  #FCFCF7;;
        width: 90%;
        font-size: 12px;
        margin-top: 15%;
        .dataForm{
            width: 105%;
            .balance{
                padding: 14px;
                .freeTokensPopupWrapper{
                    margin: 0;
                }
            }
        }
        .buttonWrapper{
            width: 105%;
        }
    }
    .connectWindow {
        width: 75%;
        .guideline{
            font-size: 12px;
        }
    }
    .buttonConnecting{
        width: 100%;
        &.notConnected{
            @include connectButton();
        }
    }
    .guideline{
        font-size: 12px;
    }
}
@include semi-tablet{
    .dataForm{
        width: 90%;
        flex: 2;
     }
    .claimContainer{
        width: 50%;
        margin-top: 5%;
    }
    .connectWindow {
        width: 50%;
    }
    .buttonConnecting{
        width: 100%;
    }
}
