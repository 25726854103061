a {
    text-decoration: none;
}
  
.dropdown {
    position: absolute;
    z-index: 1000;
    list-style: none;
    margin: 0;
    padding-left: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}
