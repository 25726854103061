@mixin mobile-semi {
    @media (max-width: 640px) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin semi-tablet{
    @media only screen and (min-width: 475px) and (max-width: 998px){
        @content;
    }
}

@mixin tablet {
    @media (max-width: 1200px) {
        @content;
    }
}

@mixin desktop {
    @media only screen and (min-width: 1201px) and (max-width: 2500px) {
        @content;
    }
}

@mixin high-end {
    @media (min-width: 2501px) {
        @content;
    }
}