@import "../../styles/_inherit.scss";
.mainHeader {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    text-align: center;
    margin-top: 0;
}
.networkAndTokens{
    flex: 3;
}
.logoWrapper{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &.connect{ 
        justify-content: flex-start;
    }
}

.btnDropdown {
    position: absolute;
    left: 68%;
}

@include tablet {
    .mainHeader {
        flex-flow: row wrap;
    }
}
@include mobile {
    .mainHeader {
        margin-top: 0;
        flex-flow: row wrap;
        font-size: 12px;
        :nth-child(1) { order: 1; }
        :nth-child(2) { order: 3; }
        :nth-child(3) { order: 2; }
        .networkAndTokens{
            width: 250px;
        }
    }
}
@include mobile-semi {
    .mainHeader {
        margin-top: 0;
        flex-flow: row wrap;
        font-size: 12px;
         :nth-child(1) { order: 1; }
         :nth-child(2) { order: 3; }
         :nth-child(3) { order: 2; }
         .networkAndTokens{
            width: 250px;
         }
    }
}
@include semi-tablet{
    .mainHeader {
        margin-top: 0;
        flex-flow: row wrap;
        font-size: 12px;
        :nth-child(1) { order: 1; }
        :nth-child(2) { order: 3; }
        :nth-child(3) { order: 2; }
        .networkAndTokens{
            width: 250px;
        }
    }
   .networkAndTokens{
    flex-basis: 100%;
    width: 0;
   }
}