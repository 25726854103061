@import '../../styles/config/colors.scss';

.selectedNetworkWrapper{
    flex:1;
    margin: 1%;
    display: flex;
    justify-content: center;
    .selectedNetwork {
        color: $green;
        width: 33%;
        font-weight: 700;
        padding: 21px 21px 10px 21px;
        font-size: 14px;
        text-align: center;
        -webkit-appearance: none;
        -moz-appearance: none;
        font-family: $graphik-bold;
        .networkLabel{
            color: $light-gray;
            font-size: 12px;
            margin-right: 3%;
            font-family: $graphik-medium;
        }
    }
}

