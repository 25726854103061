@mixin smaller-icon {
    &.smaller-icon {
      margin-top: -1px;
      margin-left: 8px;
      .popup {
        width: 12px;
        height: 12px;
      }
      svg {
        max-width: 100%;
      }
    }
}
  
@mixin popup {
    .popup {
      cursor: pointer;
      position: relative;
      width: 14px;
      height: 14px;
      svg {
        position: relative;
        transition: 0.2s ease-in-out;
      }
      .text-container {
        &::before {
          content: attr(data-text);
          box-shadow: 0px 0px 3px 0.1px #dddddd;
          transition: 0.2s ease-in-out;
          opacity: 0;
          transform: translateY(-2px);
          background: $white;
          border-radius: 5px;
          line-height: 16px;
          color: $dark-gray;
          padding: 18px;
          position: absolute;
          left: -6px;
          top: -6px;
          width: 37ch;
          pointer-events: none;
          font-family: $graphik-bold;
          font-size: 12px;
          font-weight: 900;
          text-align: center;
        }
      }
  
      &.width-restriction {
        .text-container {
          &::before {
            max-width: 15vw;
          }
        }
      }
    
      &:hover {
        .text-container {
          &::before {
            pointer-events: all;
            opacity: 0.96;
            transform: translateY(0);
          }
        }
        svg {
          transform: scale(1.1);
        }
      }
  
      @include mobile {
        &.width-restriction .text-container,
        .text-container {
          &::before {
            position: fixed;
            margin: 0;
            padding: 15px 0px; 
            max-width: 100%;
            transform: translateY(0);
            bottom: 0%;
            width: 100%;
            left: 0;
            right: 0;
            top: auto;
            text-align: center;
            z-index: 1;
          }
        }
      }
    }
  }
  
.info {
    @include popup;
    @include smaller-icon;
}

.details {
   @include popup;
}