@import '../../styles/_inherit.scss';
@mixin button($background: $green) {
    height: 60px;
    padding: 20.5px 20px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: $white;
    background-color: $background;
    border: none;
    border-radius: 5px;
    margin-bottom: 5%;
    font-family: $graphik-medium;
}
.buttonWrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 330px;
    &.claiming{
        width: 75%;
    }
    &.disconnect{
        flex: 1;
        align-items: center;
    }
    button {
        &:disabled {
            background-color: $connected-color;
            cursor: not-allowed
        }
        @include button();
    }
    .buttonConnecting{
        @include button();
        font-family: $graphik-medium;
        &.hoverAction{
            cursor: not-allowed;
        }
        &.notConnected{
            border-radius: 5px;
        }
    }
    .buttonClaiming {
        @include button($background: $connected-color);
        &.hoverAction{
            cursor: not-allowed;
        }
    }
    .buttonDisconnect {
        background: $green;
        border-radius: 50%;
        margin-left: 10px;
        margin-bottom: 0;
        width: 56px;
        height: 56px;
        padding: 2px;
        svg{
            padding: 15px;
        }
    }
}
.userAddress {
    flex: 3.5;
    padding: 21px;
    text-align: center;
    outline: none;
    color: $white;
    background-color: $light-green;
    border: none;
    border-radius: 30px;
    color: $green;
    font-family: $graphik-medium;
}
