@import '../../styles/_inherit.scss';
@mixin notifications($background:  $background-notification-error, $color: $notifications-font-color-error){
    background-color: $background;
    width: 447px;
    height: 69px;
    color: $color;
    font-weight: 600;
    text-align: center;
    font-size: 14px;
    border-radius: 10px !important;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center; 
    margin: 1.5% auto !important;
    box-shadow: 0px 0px 7px 0px #d1d1d1;;
    svg{
        margin-right: 2%;
    }
    p{  
        margin-right: 4%;
    }
    .noty-reply{
        font-weight: 800;
    }
}
.noty_type__sports-icon-alert{
   @include notifications();
    svg{
        margin-left: 5%;
    }
}
.noty_type__sports-icon-success{
    @include notifications($background: $background-notification-success, $color: $notifications-font-color-success);
    a{  
        display: block;
        text-decoration: none;
        font-size: 16px;
        font-family: $graphik-bold;
        color: $notifications-font-color-success;
    }
}
@include mobile-semi {
    .noty_type__sports-icon-alert{
        width: 300px;
        font-size: 12px;
        svg{
            margin-left: 4%;
        }
    }
    .noty_type__sports-icon-success{
        width: 300px;
        font-size: 12px;
        a{
            font-size: 12px;
        }
    }
}
@include mobile {
    .noty_type__sports-icon-alert{
        width: 300px;
        font-size: 12px;
        svg{
            margin-left: 4%;
        }
    }
    .noty_type__sports-icon-success{
        width: 300px;
        font-size: 12px;
    }
    a{
        font-size: 12px;
    }
}  


 